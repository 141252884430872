@import "../../App.scss";

.child-option {
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
  width: 100%;

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

.child-data {
  display: flex;
  gap: 8px;
  align-items: center;
}

.search-wrapper {
  display: flex;
  align-items: center;
}

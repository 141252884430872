@import "../../App.scss";

.search-box {
  border: 1px solid $mainSecondary;
  display: flex;
  align-items: center;
  width: 340px;
  height: 40px;
  border-radius: 10px;
  margin-bottom: 20px;

  .MuiSvgIcon-root {
    color: $mainPrimary;
  }

  .MuiFormControl-root {
    width: 100%;
    margin-left: -10px;
  }

  fieldset {
    border: 0;
  }
}

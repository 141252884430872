@import "../../../App.scss";

.sidebar {
  min-width: 266px;
  min-height: 100vh;
  background: $mainPrimary;
  font-family: "Inter", sans-serif;
  position: relative;
  box-sizing: border-box;

  .inner-sidebar {
    position: sticky;
    top: 20px;
  }

  h3 {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .sidebar-title {
    margin-bottom: 40px;

    .logo-wrapper {
      margin-right: 16px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    h2 {
      margin: 0;
      font-size: 18px;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-weight: 600;
    }

    span {
      font-size: 12px;
    }
  }

  .primary-navigation {
    margin-bottom: 40px;
  }

  nav {
    font-family: "Inter", sans-serif;
    font-weight: 600;

    li {
      border-radius: 5px;

      svg {
        fill: $whiteCommon;
      }

    }

    li:hover {
      background-color: $hoverPrimary;

      svg {
        fill: $lightPrimary;
      }

    }

    li.active {
      background-color: $hoverPrimary;

      svg {
        fill: $lightPrimary;
      }

    }

    .sidebar-icon {
      min-width: 30px;

      svg {
        width: 20px;
      }

    }
  }
}

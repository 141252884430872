@import "../../App";

.back-container {
  display: flex;
  align-items: center;
  color: $primaryText;
  font-size: 20px;
  gap: 8px;
  margin-top: 120px;
  cursor: pointer;
}

@import "../../App";

.login-wrapper {
  display: flex;
  width: 100vw;

  .image-section {
    display: flex;
    width: 58%;
    height: 100vh;
    background-image: url("../../assets/loginBackground.png");
    background-size: cover;
    align-items: center;
    justify-content: center;

    .veerny-logo {
      height: 15%;
    }
  }

  .form-section {
    display: flex;
    width: 42%;
    height: 100vh;
    background: white;
    align-items: center;
    justify-content: center;

    h2 {
      color: $hoverPrimary;
      font-size: 34px;
      font-weight: bold;
    }
  }

  .form-elements {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    width: 75%;

    .login-input-fields {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      &__password {
        position: relative;
      }
    }
  }

  .login-button {
    background-color: $hoverPrimary;
    font-weight: 500;
    font-size: 0.9375rem;
    padding: 0.5rem 1.38rem;
  }

  .login-button:hover {
    background-color: $mainPrimary;
  }

  .show-password {
    position: absolute;
    width: 25px;
    height: 20px;
    right: 15px;
    cursor: pointer;
  }
}

.error-text {
  color: $mainError;
  margin-top: 8px;
  margin-left: 12px;
  font-size: 14px;
}

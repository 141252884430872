@import '../../../App.scss';

#title-area {
  margin: 80px 0;

  .main-button {
    background-color: $mainPrimary;
    border-radius: 10px;
    height: 42px;
  }

}
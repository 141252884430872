@import "../../App";

.radio-wrapper {
  color: $secondaryText;

  .MuiFormControlLabel-label {
    color: $secondaryText;
  }
}

.add-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px grey dashed;
  border-radius: 4px;
  padding: 16px;
  color: $secondaryText;
  cursor: pointer;
  gap: 8px;

  svg {
    height: 28px;
    width: 28px;
  }
}

.upload-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px grey dashed;
  border-radius: 4px;
  padding: 24px 16px;
  margin-top: 12px;
}

.upload-main-text {
  color: $primaryText;
  margin: 0;
  margin-top: 8px;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.upload-side-text {
  color: $secondaryText;
  font-size: 12px;
  margin: 0;
  margin-top: 8px;
}

.uploaded-file-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.uploaded-file-info {
  display: flex;
  align-items: center;
  color: black;
  gap: 8px;
}

.chip-container {
  display: flex;
  gap: 8px;
}

.add-another-entity-row  {

  .input-section-fields {
    width: calc(100% - 40px);
    gap: 20px;
  }

  .delete-entity-row {
    cursor: pointer;
    color: #3f3273;
    margin-left: 8px;
    margin-top: -10px;
  }

}

@import "../../App";

.details-table-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
}

.details-table-header-section {
  display: flex;
  padding: 16px;
  font-size: 14px;
  color: black;
}

.details-table-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.details-table-row-section {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  font-size: 14px;
  width: 100%;

  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
}

.details-person-info-wrapper {
  display: flex;
  flex-direction: column;

  p {
    color: $secondaryText;
  }

  p,
  h1 {
    margin: 0;
  }
}

.initials-circle {
  display: flex;
  color: white;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $mainPrimary;
  text-transform: uppercase;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

@import "../../App";

.tablerow-avatar {
  img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-right: 12px;
  }
}

.circle-array-wrapper {
  display: flex;
  align-items: center;

  img {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    margin-left: -6px;
  }
}

.tablecell-image-array {
  display: flex;
  .tablerow-avatar {
    margin-left: -12px;
  }
}

.MuiDataGrid-cell .initials-tooltip,
.details-table-row-section .initials-tooltip {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 8px;
  left: 35px;
  z-index: 9999;
  background: #fff;
  padding: 0 5px;
  transition: opacity 0.5s ease;
}

.hovered-class {
  opacity: 1 !important;
  visibility: visible !important;
  transition-delay: 1s;
  color: #000;
}

.additional-number-container:hover {
  cursor: pointer;
}

.circle-array-wrapper:last-child {
  margin-left: 4px;
}

.additional-number-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  background-color: $mainPrimary;
  text-transform: capitalize;
  width: 34px;
  height: 34px;
  margin-left: -6px;
}

@-moz-document url-prefix() {
  .additional-number-container {
    display: block;
    line-height: 37px;
    text-align: center;
  }
}

.group-color-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-transform: capitalize;
}

.color-circle {
  border-radius: 50%;
  width: 21px;
  height: 21px;
}

.circle-array-wrapper {
  > div,
  > img {
    border: 3px solid $whiteCommon;
  }
}

@import "../../App";

.menu-container {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  position: relative;

  .language-flag {
    width: 24px;
    height: 18px;
    cursor: pointer;
  }

  .initials-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $mainPrimary;
    text-transform: uppercase;
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  .language-submenu {
    position: absolute;
    top: 50%;
    right: 100px;
    padding: 8px;
    box-shadow: 0px 0px 19.1px 0px rgba(229, 229, 229, 0.25);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  .language-option {
    display: flex;
    align-items: center;
    color: $primaryText;
    gap: 12px;
    padding: 8px 16px;
    border-radius: 4px;
    z-index: 1001;
  }

  .language-option:hover {
    background-color: #0000000a;
    cursor: pointer;
  }

  .language-option-flag {
    width: 24px;
    height: 24px;
  }

  .logout-submenu {
    color: $primaryText;
    position: absolute;
    top: 100%;
    margin-top: 10px;
    right: 0;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0px 0px 19.1px 0px rgba(229, 229, 229, 0.25);
    z-index: 1001;
    background-color: white;
  }

  .logout-user-info {
    gap: 4px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: 2px solid #0000001f;
    padding: 8px 16px;
  }

  .logout-user-info:hover {
    background-color: #0000000a;
  }

  .logout-heading {
    margin: 0;
    font-size: 14px;
  }

  .logout-email {
    margin: 0;
    font-size: 12px;
    color: $secondaryText;
  }

  .logout-action {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 8px 16px;
  }

  .logout-action:hover {
    background-color: #0000000a;
    cursor: pointer;
  }
}

@import "../../App";

.details-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  margin-top: 150px;
  margin-bottom: 74px;
}

.edit-container {
  display: flex;
  gap: 8px;
  font-size: 18px;
  color: $primaryText;
  align-items: center;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
}

.details-page-layout {
  display: flex;
  gap: 36px;
}

.details-page-section {
  color: $primaryText;
  width: 100%;
  box-shadow: 0px 0px 19.1px 0px rgba(166, 166, 166, 0.25);
  height: 100%;
  border-radius: 8px;

  .multi-section {
    display: flex;
    flex-direction: column;
    gap: 44px;
    border-bottom: 1px #f4f4f4;

    .vertical-section {
      box-shadow: 0px 0px 19.1px 0px rgba(166, 166, 166, 0.25);
      border-bottom: 1px solid #f4f4f4;
      border-radius: 8px;
    }
  }
}

.details-page-section.child-details,
.details-page-section.group-details {
  box-shadow: none;
  border-radius: 0;
}

.details-page-section-header {
  display: flex;
  flex-direction: column;
  background-color: $mainPrimary;
  color: $whiteCommon;
  font-weight: 500;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 16px;
}

.details-page-section-row {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #f4f4f4;

  img {
    width: 18px;
    height: 18px;
    border-radius: 2px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: none;
  }
}

.details-scroll-wrapper {
  max-height: 408px;
  overflow-y: auto;
}

.delete-option-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.language-icon {
  width: 20px;
  height: 20px;
}

.details-table-row {
  background: $whiteCommon;
}

@media screen and (max-width: 992px) {
  .details-page-header {
    margin-top: 100px;
  }
}

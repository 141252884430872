@import "../../App";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  color: $primaryText;

  &__container {
    overflow: auto;
    padding: 0em 2em;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1em;
    font-weight: 600;
  }

  &__content {
    max-width: 800px;
    max-height: 90vh;
    background: white;
    padding: 2em 0.2em;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    width: 70vw;
    position: relative;
  }

  &__close-icon {
    cursor: pointer;
  }
}

@import "../../App";

.details-page-header-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;

  img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 4px solid purple;
    object-fit: cover;
  }
}

.overview-row {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 4px;
  border-bottom: 1px solid #f4f4f4;

  p {
    margin: 0;
    font-size: 14px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: none;
  }
}

.overview-row-header {
  color: $mainPrimary;
}

.overview-row-data {
  color: $secondaryText;
}

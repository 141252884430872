@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

// Text color constants
$primaryText: #483359;
$secondaryText: #626262;
$disabledText: #d9d9d9;

// Primary color constants
$mainPrimary: #3f3273;
$darkPrimary: #301f63;
$lightPrimary: #9747ff;
$contrastPrimary: #fff;
$hoverPrimary: #524086;
$selectedPrimary: #d9d9d9;
$focusPrimary: #d9d9d9;
$focusVisiblePrimary: #d9d9d9;
$outlinedBorderPrimary: #d9d9d9;
$primaryHeader: #f5f5fe;

// Secondary color constants
$mainSecondary: #d9d9d9;
$darkSecondary: #d9d9d9;
$lightSecondary: #d9d9d9;
$contrastSecondary: #d9d9d9;

// Error color constants
$mainError: #d32f2f;
$darkError: #c62828;
$lightError: #ef5350;
$errorContrast: #fff;

//Warning color constants
$mainWarning: #ef6c00;
$darkWarning: #ef6c00;
$lightWarning: #ff9800;

//Info color constants
$mainInfo: #d9d9d9;
$darkInfo: #d9d9d9;
$lightInfo: #d9d9d9;
$contrastInfo: #d9d9d9;

//Success color constants
$mainSuccess: #d9d9d9;
$darkSuccess: #d9d9d9;
$lightSuccess: #d9d9d9;
$contrastSuccess: #d9d9d9;

//Background color constants
$mainBackground: #d9d9d9;

//Action color constants
$activeAction: #d9d9d9;
$hoverAction: #f5f5f5;
$selectedAction: #fbfbfc;
$disabledBackgroundAction: #d9d9d9;
$focusAction: #d9d9d9;
$disabledAction: #d9d9d9;

//Common color constants
$whiteCommon: #fff;
$blackCommon: #000;

body {
  color: $whiteCommon;
  background-color: $whiteCommon;
  font-family: "Inter", sans-serif;
  scrollbar-width: thin; /* "thin" or "auto" */
  scrollbar-color: $primaryText #dad6de; /* scrollbar thumb and track colors */
}

h1,
h2,
h3 {
  font-family: "Plus Jakarta Sans", sans-serif;
}

h1 {
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 0.25px;
  color: $primaryText;
}

.content {
  width: calc(100% - 266px);
}

.padding-wrapper {
  padding: 30px 40px;
}

.table-header {
  color: $primaryText;
  font-weight: 700;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.MuiDataGrid-columnHeaders {
  background-color: $selectedAction;
}

.data-grid-wrapper {
  width: 100%;
}
/* MUI HACKS */

.css-1hbbmh9-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-1hbbmh9-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-sortIcon {
  opacity: inherit !important;
}

.MuiDataGrid-iconButtonContainer {
  visibility: visible !important;
}

.MuiDataGrid-sortIcon {
  opacity: inherit !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dad6de;
  border-radius: 10px;
  color: white;
}

::-webkit-scrollbar-thumb {
  background: $primaryText;
  border-radius: 10px;
}

.MuiDataGrid-main {
  height: 576px;

  .css-qvtrhg-MuiDataGrid-virtualScroller {
    min-height: 50px;
  }

}

@import "../../App";

.close-icon {
  position: absolute;
  z-index: 5;
  top: 50%;
  right: 0;
  margin-top: -6px;
  margin-right: 15px;
  cursor: pointer;
}

.phone-input {
  position: relative;
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 52px;
}

.section-container {
  display: flex;
  justify-content: space-between;
  padding: 32px 24px;
  box-shadow: 0px 0px 30px 0px rgba(229, 229, 229, 0.25);

  h3 {
    color: black;
  }
}

.input-section {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 20px;

  h4 {
    color: $primaryText;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

.delete-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid red;
  border-radius: 10px;
  color: red;
  background-color: transparent;
  width: 15%;
  cursor: pointer;
}

.form-actions {
  display: flex;
  gap: 24px;
  justify-content: flex-end;

  .cancel-action,
  .submit-action {
    background-color: $mainPrimary;
    display: flex;
    padding: 8px 22px;
    border-radius: 16px;
  }

  .cancel-action {
    background-color: transparent;
    color: $secondaryText;
  }

  .cancel-action:hover,
  .submit-action:hover {
    cursor: pointer;
  }
}

.special-label,
.country-name {
  color: #797979;
  font-family: "Inter", sans-serif;
}

.form-control {
  width: 100% !important;
  font-family: "Inter", sans-serif !important;
}

.color-dropdown {
  .group-color-wrapper {
    justify-content: start;
  }
}

.checkbox-selection {
  .css-u4tvz2-MuiFormLabel-root.Mui-focused {
    color: $mainPrimary;
  }

  label {
    min-width: 23%;

    span {
      color: $secondaryText;
    }
  }
}

.multi-select-dropdown {
  img {
    max-width: 20px;
  }
}

li.MuiMenuItem-root {
  img {
    max-width: 20px;
    margin-right: 5px;
  }
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper
  .css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0px;
}

.selected-pills {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;

  div {
    border-radius: 4px;
    background: $mainPrimary;
    padding: 8px 8px 8px 22px;
    font-weight: 500;
  }
}

.select-search-header {
  padding: 16px 20px;
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 1px solid #f4f4f4;
}

.MuiList-root .search-select-subheader {
  padding: 0;
}

.select-search-input {
  input {
    padding: 16px 0;
  }
}

.select-search-item {
  margin: 0;
  padding: 8px 12px;
  border-bottom: 1px solid #f4f4f4;
}

.select-search-item:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.phone-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-another-entity-row  {

  .input-section-fields {
    width: calc(100% - 40px);
    gap: 20px;
  }

  .delete-entity-row {
    cursor: pointer;
    color: #3f3273;
    margin-left: 8px;
    margin-top: -10px;
  }

}